*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}

body {
  background-color: rgb(255, 253, 235);
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas {
  display: inline-block;
  max-width: 100%;
}

/* p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
} */

#root,
#__next {
  isolation: isolate;
}

a {
  color: black;
}

h1 {
  font-family: 'Montserrat-Bold';
}

p {
  font-family: 'Montserrat-Regular';
}

* {
  font-family: 'Montserrat-Regular';
}

@font-face {
  font-family: 'CoveredByYourGrace-Regular';
  src: url('./styles/CoveredByYourGrace-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./styles/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}
@font-face {
  font-family: 'Montserrat-ExtraLight';
  src: url('./styles/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}
@font-face {
  font-family: 'Montserrat-Thin';
  src: url('./styles/Montserrat-Thin.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./styles/Montserrat-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}
@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('./styles/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  max-width: 100%;
  overflow-x: hidden;
}

p {
  font-family: 'DM-Sans';
}

h1 {
  font-family: 'DM-SerifDisplay';
}
